import * as React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Layout from '@components/layout';
import EndMark from '@images-svg/endmark.svg';
import StartMark from '@images-svg/startmark.svg';
import SubtleBg from '@components/subtlebg';
import BGKauppa from '@components/bgkauppa';
import TheEnd from '@components/theend';
import NarratorImg from '@images/narrator.png';
import { FooterInnerPage } from '@components/footer';
import { Link } from 'gatsby';
import Seo from '@components/seo';
import Content from '@common/content';

const REFERENCE = {
  meta: {
    referenceTitle: 'Kaupan ala',
    highlights: [
      'Asiakkaat ovat liikkuvaisia - miten saadaan asiakas palaamaan?',
      'Ulkomaisten verkkokauppojen tuoma kilpailupaine on kova',
      'Toimituslupauksien merkitys - tavaraa täytyy löytyä ja nopeasti'
    ]
  },
  discussion: [
    {
      text: 'Hei! Kuka olet ja mikä on tyypillinen työnkuvasi?'
    },
    {
      nick: 'Matias',
      className: 'cyan right',
      text: 'Koodari/arkkari, kiinnostus- ja osaamisalueena erityisesti toimitusketjun prosessit, aina monikanavaisesta myynnistä talous- ja varastojärjestelmien kautta last-mile deliveryyn.'
    },
    {
      nick: 'Pete',
      className: 'light right',
      text: 'Laurosen Pete, toimin pilvipalvelujen ratkaisuarkkitehtina Taitopilvellä 7 vuotta pian takana. Useimmiten koodaan kädet savessa, välillä suunnittelen isompia kokonaisuuksia ja määrittelen prosesseja asiakkaan kanssa.'
    },
    //---
    {
      text: 'Miten päädyit ohjelmistoalalle?'
    },
    {
      nick: 'Matias',
      className: 'cyan right',
      text: 'Ohjelmoija toisessa polvessa. Iskän jutut reikäkorttiajoista alkaen kuulostivat hauskoilta. Jotkus asiat ovat muuttuneet niistä ajoista aivan valtavasti, jotkut ei sitten ollenkaan.'
    },
    //---
    {
      text: 'Mitkä ovat tyypillisiä haasteita kaupan alalla?'
    },
    {
      nick: 'Matias',
      className: 'cyan right',
      text: 'Toimitusketjulle tärkein asiakas on loppuasiakas, siis kuluttaja joka on tehnyt tilauksen. Toimituslupauksia annetaan ensimmäisestä toimitusaika-arviosta alkaen ja lunastetaan aina sujuvaan palautusprosessiin saakka. Tavoite on sinänsä yksinkertainen, mutta sen saavuttamiseksi jokaisen ketjun osan täytyy toimia saumattomasti yhteen.'
    },
    //---
    {
      text: 'Mikä on palkitsevinta kaupan alan projekteissa?'
    },
    {
      nick: 'Matias',
      className: 'cyan right',
      text: 'Useaa järjestelmää koskeva featuren tuotantoonvienti yhdellä iteraatiolla; tiketti suunniteltu hyvin testausohjeita myöten, tehty toteutus, yksikkötestit sekä E2E-testiatuomaatio laadukkaasti. Kaikki toimii yhteen ja saa painaa featuren tuotantoon.'
    },
    //--
    {
      text: 'Kuvaile Taitopilven lähestymistapaa ohjelmistokehitykseen?'
    },
    {
      nick: 'Matias',
      className: 'cyan right',
      text: 'Meillä on työntekijöiden laatimasta strategiasta alkaen linjattu, että haluamme olla osa asiakkaan tiimiä ja tarjota asiakkaalle aina parhaan ratkaisun, vaikka se olisi pois meidän töistä. Käytännössä työskennellään omilla vahvuusalueilla ja panostetaan osaamisen syventämiseen ja laajentamiseen.'
    },
    //--
    {
      text: 'Millaisia onnistuneita projekteja olette tehneet kaupan alalle?'
    },
    {
      nick: 'Pete',
      className: 'light right',
      text: 'Keskitetty järjestelmä toimituksien hallintaan, jota useat eri verkkokaupat käyttävät ja josta on integraatiot kymmeniin eri-ikäisiin ja -kokoisiin järjestelmiin.'
    },
    //---
    {
      text: 'Mikä on tuottanut haasteita?'
    },
    {
      nick: 'Pete',
      className: 'light right',
      text: 'Pitkän toimitusketjun testaus prosessin loppupään osille; et voi palauttaa jotain mitä ei ole toimitettu, eikä toimittaa voi jos ei ole tilattu. Heijastevaikutukset vaikkapa tilausprosessin alkupäässä tehdyistä muutoksista saattavat ilmetä vasta pitkän ajan päästä palautuskäsittelyyn liittyvissä talouden prosesseissa.'
    },
    //---
    {
      text: 'Erikoisin tilanne tuotannossa?'
    },
    {
      nick: 'Pete',
      className: 'light right',
      text: 'Kolmannen osapuolen järjestelmä alkoi yskimään tietyn kuorman kohdalla. Koska kolmas osapuoli ei voinut nopeasti skaalata omaa päätään, meidän täytyi tehdä kiertotie omassa päässä, että saatiin järjestelmä kestämään kasvava kuorma.'
    },
    //---
    {
      text: 'Mitä teknologioita suosittelet?'
    },
    {
      nick: 'Pete',
      className: 'light right',
      text: 'AWS Step Functions prosessimoottorina istuu äärimmäisen hyvin kaupan alan haasteisiin. Nostaa business-logiikan, säännöt ja stepit ulos koodista ja visuaaliseen muotoon, josta voi keskustella asiakkaan kanssa suoraan molemmille sopivalla kielellä. Helpottaa myös tuotannon kiemuroiden ja erikoistapauksien selvitystä kun yksittäisen prosessin “suorituksen" kaikki tehdyt toimet ja stepit on nostettu kaikkien näkyville. Teknologiamielessä erittäin kevyt ottaa käyttöön, ja serverless kun on, ei vaadi investointeja, lisenssejä eikä infran ylläpitoa.'
    },
    //---
    {
      text: 'Millaisia innovatiivisia ratkaisuja olette keksineet?'
    },
    {
      nick: 'Pete',
      className: 'light right',
      text: 'Otimme serveless mikropalveluarkkitehtuurin käyttöön. Iloksemme huomattiin, että suurenkin tuotantojärjestelmän pyörittämisen kustannukset jäivät murto-osaan siitä, mitä perinteisen monoliittitoteutuksen omilla palvelimilla ajaminen maksoi. Lisäksi kehitystyö nopeutui huomattavasti.'
    },
    //---
    {
      text: 'Miten asiakasorganisaation ulkopuolinen toimija voi parantaa yhteistyötä eri stakeholderien välillä?'
    },
    {
      nick: 'Matias',
      className: 'cyan right',
      text: 'Prosesseja ja priorisointia voi tehdä puhtaammin teknisestä näkökulmasta. Cross-functional tiimin tärkein tuotos on kuitenkin laadukkaasti toimiva softa; kun järjestelmä toimii hyvin, kaikki nukkuvat yönsä paremmin.'
    },
    //---
    {
      text: 'Tuleeko sinulle mieleen jotain hauskoja sattumuksia matkan varrelta?'
    },
    {
      nick: 'Matias',
      className: 'cyan right',
      text: 'Joskus on pesukonetta oltu tuomassa Testi Testaajan kotiin Testitielle, vaikka ainoa oikea tieto tilauksella oli postinumero.'
    },
    //---
    {
      text: 'Miten pysyt ajan tasalla trendeissä?'
    },
    {
      nick: 'Matias',
      className: 'cyan right',
      text: 'Aktiivinen seuranta mitä markkinassa tapahtuu rapakon toisella puolella. Erityisesti kompleksien toimitusketjujen rakentamisessa ollaan jenkeissä yleensä hieman pidemmällä, kun toimituslogistiikan vaihtoehtoja on Suomeen verrattuna valtavasti. Pitkien putkien teknisessä ja toiminnallisessa laadussa, läpinäkyvyydessä ja seurattavuudessa sen sijaan me ollaan huipputasolla.'
    },
    //---
    {
      text: 'Mihin uskot alan kehittyvän?'
    },
    {
      nick: 'Matias',
      className: 'cyan right',
      text: 'Saadaan perinteisen data-analyysin tuottamien “100% oikeiden vastauksien” lisäksi hyvin koulutettujen tekoälymallien tunnistamia, aiemmin piiloon jääneitä toimitusprosessien poikkemien mahdollisia juurisyitä esiin. Kaikkia juurisyitä ei edes voi tai kannata korjata, mutta asiakaskokemusta voidaan parantaa proaktiivisuutta lisäämällä ja reagointiaikaa lyhentämällä. Tekoäly voisi tunnistaa esimerkiksi tietyntyyppisiä tuotekombinaatioita sisältävät tilaukset, joissa on riski toimituslupauksen pettämiseen. Sellaiset tilaukset voidaan ottaa erityiseen seurantaan ja suunnitella valmiiksi vaihtoehtoisia toimitusmalleja, joilla toimituslupaus saadaan täytettyä ilman kustannusten karkaamista.'
    },
    //---
    {
      text: 'Mitä vinkkejä antaisit toimialalla työskenteleville henkilöille?'
    },
    {
      nick: 'Pete',
      className: 'light right',
      text: 'Uutta asiaa suunnitellessanne, miettikää ja piirtäkää ensin prosessi isossa kuvassa, sen jälkeen käykää tarvittavat viestit paperilla läpi - tällöin saatte selville poikkeukset ja puuttuvat tiedot, ja osaatte ottaa nämä huomioon alusta alkaen.'
    },
    //---
    {
      text: 'Jos saisit tehdä ohjelmistoprojektia kenen tahansa fiktiivisenkin henkilön kanssa, niin kuka se olisi?'
    },
    {
      nick: 'Pete',
      className: 'light right',
      text: 'Elon Muskin kanssa olisi mielenkiintoista työskennellä. Hän on ilmeisesti varsin vaativa mutta myös innovatiivinen. Täytyisi vaan raivata pari viikkoa kalenterista täysin vapaaksi ja tehdä sitten pitkää päivää keskittyneesti kovalla tiimillä, olisi mielenkiintoista nähdä mitä siinä voisi saada aikaan. AWS:n työkalut ja palvelupaletti mahdollistavat sen, että pienessäkin ajassa voi saada paljon toimivaa tuotantoon.'
    },
    {
      nick: 'Matias',
      className: 'cyan right',
      text: 'SAP:n integraatiovärkin kehittäjien kanssa, vois kysyä että mitä ovat miettineet...'
    },
    //---
    {
      text: 'Mottosi?'
    },
    {
      nick: 'Pete',
      className: 'light right',
      text: 'Fail fast, test and fix before production'
    },
    {
      nick: 'Matias',
      className: 'cyan right',
      text: 'Virheistä oppii, mutta ei niitä kaikkia tarvitse itse tehdä.'
    }
  ]
};

const Kauppa = () => (
  <Layout hasHeroArea={false} isAvailableInEnglish={false}>
    <Seo title="Kaupan ala" description="Matias ja Pete kertovat kokemuksia toimialasta" />
    <SubPageBackground>
      <GradientBg>
        <Content>
          <SubpageNav>
            <p className="text-2xl uppercase mt-10 mb-0 font-condensed">
              <Link to="/#asiakascaset">Asiakascaset</Link>
            </p>
            <h2>☛ {REFERENCE.meta.referenceTitle}</h2>
            <p className="italic font-light">Matias ja Pete kertovat kokemuksia toimialasta</p>
          </SubpageNav>
          <BGKauppa>
            <fieldset>
              <legend>Toimialan erityispiirteet</legend>
              <ul>
                {REFERENCE.meta.highlights.map((highlight, i) => (
                  <li key={i}>{highlight}</li>
                ))}
              </ul>
            </fieldset>
          </BGKauppa>
          <StyledStartMark />
          <SubtleBg>
            <Discussion>
              {REFERENCE.discussion.map((speechBubble, i) => (
                <SpeechBubble
                  key={i}
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  className={speechBubble.className ? speechBubble.className : 'narrator'}
                >
                  {!speechBubble.className && <img src={NarratorImg} alt="Kysyjä" />}
                  <p>{speechBubble.text}</p>
                  {speechBubble.nick && <p className="nick">{speechBubble.nick}</p>}
                </SpeechBubble>
              ))}
            </Discussion>
          </SubtleBg>
          <StyledEndMark />
          <CrossRef>
            <Link className="button" to="/#asiakascaset">
              Muut asiakascaset
            </Link>
          </CrossRef>
        </Content>
      </GradientBg>
      <FooterInnerPage />
      <TheEnd />
    </SubPageBackground>
  </Layout>
);

const GradientBg = styled.div`
  && {
  }
`;

const SubPageBackground = styled.section`
  && {
    background: var(--t-gray-dark-2);
    background: linear-gradient(0deg, var(--t-gray-dark-2) 0%, var(--t-black) 100%);
    color: var(--t-white);
  }
`;

const SubpageNav = styled.nav`
  && {
    padding: 1em;
    @media (min-width: 35em) {
      padding: 2em;
    }
    a {
      color: var(--t-white);
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const CrossRef = styled.nav`
  && {
    padding-bottom: 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    a {
      padding: 0.5em;
      text-shadow: 1px 1px 3px black;
      color: var(--t-white);
      text-decoration: none;
      cursor: pointer;
    }
    h3 {
      padding: 0.75em 2em;
    }
  }
`;

const Discussion = styled.article`
  && {
    display: flex;
    flex-direction: column;
    padding: 0.25em 0.5em;
    border: 8px double var(--t-gray-dark-2);
    @media (min-width: 35em) {
      padding: 1.5em 2em;
    }
  }
`;

const CYAN = `var(--t-cyan-light)`;
const LIGHT = `var(--t-gray-light)`;
const MAGENTA = `var(--t-magenta-light)`;
const SpeechBubble = styled(motion.section)`
  && {
    padding: 0.5em;
    @media (min-width: 35em) {
      padding: 0.5em;
    }
    margin: 0.75em 0;
    p {
      line-height: 1.44;
      margin: 0.5em;
      @media (min-width: 35em) {
        margin: 1em;
      }
    }
    .nick {
      float: right;
      margin: 0 1em;
      font-family: 'built_rg';
      font-size: 150%;
      letter-spacing: 0.00625em;
      &:before {
        content: '—';
        padding-right: 0.25em;
      }
    }
    &.narrator {
      display: flex;
      align-items: center;
      background: rgba(0, 0, 0, 0.35);
      color: var(--t-black);
      background: var(--t-yellow);
      box-shadow: 0 0.5em 2em black;
      width: fit-content;
      padding: 0.5em 1.5em;
      font-style: italic;
      border-radius: 0.5em;
      img {
        width: 30px;
        box-shadow: 0 0.1em 0.2em black;
      }
    }
    &:not(.narrator) {
      border-bottom: 3px ridge rgba(0, 0, 0, 0.7);
      border-radius: 0.5em;
      color: var(--t-black);
      position: relative;
      width: 40em;
      max-width: 66.6%;
      &:before {
        content: '';
        width: 0px;
        height: 0px;
        position: absolute;
        color: orange;
        font-size: 400%;
      }
    }
    &.left {
      align-self: flex-start;
      margin-left: 30px;
    }
    &.right {
      align-self: flex-end;
      margin-right: 30px;
    }
    &.light {
      background: ${LIGHT};
      &:before {
        border-left: 15px solid ${LIGHT};
        border-right: 15px solid transparent;
        border-top: 15px solid ${LIGHT};
        border-bottom: 15px solid transparent;
        right: -16px;
        top: 0px;
      }
    }
    &.cyan {
      background: ${CYAN};
      &:before {
        border-left: 15px solid transparent;
        border-right: 15px solid ${CYAN};
        border-top: 15px solid ${CYAN};
        border-bottom: 15px solid transparent;
        left: -16px;
        top: 0px;
      }
    }
    &.magenta {
      background: ${MAGENTA};
      &:before {
        border-left: 15px solid transparent;
        border-right: 15px solid ${MAGENTA};
        border-top: 15px solid ${MAGENTA};
        border-bottom: 15px solid transparent;
        left: -16px;
        top: 0px;
      }
    }
  }
`;

const StyledEndMark = styled(EndMark)`
  && {
    margin: 0 auto;
    box-sizing: content-box;
    padding: 2em;
    display: block;
    width: 100px;
  }
`;

const StyledStartMark = styled(StartMark)`
  && {
    margin: 0 auto;
    box-sizing: content-box;
    padding: 2em;
    display: block;
    width: 100px;
  }
`;

export default Kauppa;
